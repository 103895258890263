import React, { Component } from 'react';
import PageNotFound from '../Images/readingtunic.jpg'
import { NavLink } from "react-router-dom";
class PageNotFoundError extends Component {
    render() {
        return (
            <div>
                <img alt="404 tu nic" src={PageNotFound} />
                <h1>404 Page Not Found!</h1>
                <h2>Zašels moc daleko.</h2>
                <NavLink to="/">
                    Go Home
                </NavLink>
            </div>
        );
    }
}

export default PageNotFoundError;