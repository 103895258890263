import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";

class Success extends Component {
    render() {
        return (
            <Grid item>
                <h1 style={{ textAlign: "center", color: "#00e676" }}>Úspěšně jsi odeslal žádost o unban.</h1>
                <h4>Většinou unban dáme po pár dnech, zkus se připojit na server mezitím</h4>
            </Grid>
        );
    }
}

export default Success;